*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.visible {
  visibility: visible;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.left-0 {
  left: 0;
}

.right-5 {
  right: 1.25rem;
}

.top-0 {
  top: 0;
}

.top-\[80vh\] {
  top: 80vh;
}

.z-10 {
  z-index: 10;
}

.z-30 {
  z-index: 30;
}

.z-50 {
  z-index: 50;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.-ml-0 {
  margin-left: 0;
}

.-ml-\[-20px\] {
  margin-left: 20px;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-32 {
  margin-top: -8rem;
}

.-mt-\[30px\] {
  margin-top: -30px;
}

.-mt-\[38px\] {
  margin-top: -38px;
}

.-mt-\[70px\] {
  margin-top: -70px;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-0 {
  margin-left: 0;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-\[0px\] {
  margin-left: 0;
}

.ml-\[100px\] {
  margin-left: 100px;
}

.ml-\[15px\] {
  margin-left: 15px;
}

.ml-\[30px\] {
  margin-left: 30px;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-\[10px\] {
  margin-top: 10px;
}

.mt-\[30px\] {
  margin-top: 30px;
}

.mt-\[5px\] {
  margin-top: 5px;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.table {
  display: table;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-64 {
  height: 16rem;
}

.h-\[100px\] {
  height: 100px;
}

.h-\[100vh\] {
  height: 100vh;
}

.h-\[1100px\] {
  height: 1100px;
}

.h-\[110px\] {
  height: 110px;
}

.h-\[120px\] {
  height: 120px;
}

.h-\[1300px\] {
  height: 1300px;
}

.h-\[140px\] {
  height: 140px;
}

.h-\[180px\] {
  height: 180px;
}

.h-\[200px\] {
  height: 200px;
}

.h-\[250px\] {
  height: 250px;
}

.h-\[270px\] {
  height: 270px;
}

.h-\[320px\] {
  height: 320px;
}

.h-\[350px\] {
  height: 350px;
}

.h-\[400px\] {
  height: 400px;
}

.h-\[500px\] {
  height: 500px;
}

.h-\[50px\] {
  height: 50px;
}

.h-\[600px\] {
  height: 600px;
}

.h-\[60px\] {
  height: 60px;
}

.h-\[70px\] {
  height: 70px;
}

.h-\[80px\] {
  height: 80px;
}

.h-\[80vh\] {
  height: 80vh;
}

.h-\[900px\] {
  height: 900px;
}

.h-auto {
  height: auto;
}

.h-screen {
  height: 100vh;
}

.min-h-screen {
  min-height: 100vh;
}

.w-36 {
  width: 9rem;
}

.w-64 {
  width: 16rem;
}

.w-\[100vw\] {
  width: 100vw;
}

.w-\[160px\] {
  width: 160px;
}

.w-\[185px\] {
  width: 185px;
}

.w-\[2000px\] {
  width: 2000px;
}

.w-\[200px\] {
  width: 200px;
}

.w-\[270px\] {
  width: 270px;
}

.w-\[310px\] {
  width: 310px;
}

.w-\[400px\] {
  width: 400px;
}

.w-\[50px\] {
  width: 50px;
}

.w-\[60px\] {
  width: 60px;
}

.w-\[650px\] {
  width: 650px;
}

.w-\[70px\] {
  width: 70px;
}

.w-\[80px\] {
  width: 80px;
}

.w-\[80vw\] {
  width: 80vw;
}

.w-\[85vw\] {
  width: 85vw;
}

.w-\[90px\] {
  width: 90px;
}

.w-\[90vw\] {
  width: 90vw;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.min-w-full {
  min-width: 100%;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-md {
  max-width: 28rem;
}

.flex-1 {
  flex: 1;
}

.shrink {
  flex-shrink: 1;
}

.grow {
  flex-grow: 1;
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes fade-down {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-down {
  animation: fade-down var(--tw-animate-duration, 1s) var(--tw-animate-easing, ease) var(--tw-animate-delay, 0s) var(--tw-animate-iteration, 1) var(--tw-animate-fill, both);
}

@keyframes flip-down {
  0% {
    transform-origin: top;
    transform: rotateX(-90deg);
  }

  100% {
    transform-origin: top;
    transform: rotateX(0);
  }
}

.animate-flip-down {
  animation: flip-down var(--tw-animate-duration, 1s) var(--tw-animate-easing, ease) var(--tw-animate-delay, 0s) var(--tw-animate-iteration, 1) var(--tw-animate-fill, both);
}

.cursor-pointer {
  cursor: pointer;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.scroll-smooth {
  scroll-behavior: smooth;
}

.rounded {
  border-radius: .25rem;
}

.rounded-\[10px\] {
  border-radius: 10px;
}

.rounded-\[50\%\] {
  border-radius: 50%;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-b-\[10px\] {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.rounded-l-lg {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-r-lg {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

.rounded-bl-lg {
  border-bottom-left-radius: .5rem;
}

.rounded-bl-md {
  border-bottom-left-radius: .375rem;
}

.rounded-br-lg {
  border-bottom-right-radius: .5rem;
}

.rounded-br-md {
  border-bottom-right-radius: .375rem;
}

.rounded-tl-lg {
  border-top-left-radius: .5rem;
}

.rounded-tl-md {
  border-top-left-radius: .375rem;
}

.rounded-tr-lg {
  border-top-right-radius: .5rem;
}

.rounded-tr-md {
  border-top-right-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-\[10px\] {
  border-width: 10px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l-2 {
  border-left-width: 2px;
}

.border-double {
  border-style: double;
}

.border-\[\#006D77\] {
  --tw-border-opacity: 1;
  border-color: rgb(0 109 119 / var(--tw-border-opacity));
}

.border-\[\#F0C48A\] {
  --tw-border-opacity: 1;
  border-color: rgb(240 196 138 / var(--tw-border-opacity));
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

.border-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgb(249 115 22 / var(--tw-border-opacity));
}

.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

.border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgb(185 28 28 / var(--tw-border-opacity));
}

.border-white {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.bg-\[\#006D77\], .bg-\[\#006d77\] {
  --tw-bg-opacity: 1;
  background-color: rgb(0 109 119 / var(--tw-bg-opacity));
}

.bg-\[\#433878\] {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 120 / var(--tw-bg-opacity));
}

.bg-\[\#D56A34\] {
  --tw-bg-opacity: 1;
  background-color: rgb(213 106 52 / var(--tw-bg-opacity));
}

.bg-\[\#E5E5E5\] {
  --tw-bg-opacity: 1;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity));
}

.bg-\[\#d8590a\] {
  --tw-bg-opacity: 1;
  background-color: rgb(216 89 10 / var(--tw-bg-opacity));
}

.bg-\[\#ffa16f\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 161 111 / var(--tw-bg-opacity));
}

.bg-\[orangered\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 69 0 / var(--tw-bg-opacity));
}

.bg-\[rgba\(0\,0\,0\,0\.5\)\] {
  background-color: #00000080;
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity));
}

.bg-orange-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}

.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-opacity-80 {
  --tw-bg-opacity: .8;
}

.bg-\[url\(\'background-main\.jpg\'\)\] {
  background-image: url("background-main.5720b389.jpg");
}

.bg-\[url\(\'https\:\/\/assets\.medpagetoday\.net\/media\/images\/99xxx\/99926_m\.jpg\'\)\] {
  background-image: url("https://assets.medpagetoday.net/media/images/99xxx/99926_m.jpg");
}

.bg-\[url\(\'https\:\/\/c4\.wallpaperflare\.com\/wallpaper\/407\/626\/415\/cells-biology-science-wallpaper-preview\.jpg\'\)\] {
  background-image: url("https://c4.wallpaperflare.com/wallpaper/407/626/415/cells-biology-science-wallpaper-preview.jpg");
}

.bg-\[url\(\'https\:\/\/cdn\.pixabay\.com\/photo\/2023\/01\/23\/18\/00\/blood-7739308_960_720\.jpg\'\)\] {
  background-image: url("https://cdn.pixabay.com/photo/2023/01/23/18/00/blood-7739308_960_720.jpg");
}

.bg-\[url\(\'https\:\/\/cdn\.pixabay\.com\/photo\/2023\/08\/29\/18\/29\/ai-generated-8221936_960_720\.png\'\)\] {
  background-image: url("https://cdn.pixabay.com/photo/2023/08/29/18/29/ai-generated-8221936_960_720.png");
}

.bg-\[url\(\'https\:\/\/cdn\.prod\.website-files\.com\/5d9667bad4a41e222995e15b\/63d30b2b0699f4f730a4f4b4_Msc\%20diabetes\%20copy\.webp\'\)\] {
  background-image: url("https://cdn.prod.website-files.com/5d9667bad4a41e222995e15b/63d30b2b0699f4f730a4f4b4_Msc%20diabetes%20copy.webp");
}

.bg-\[url\(\'https\:\/\/d2jx2rerrg6sh3\.cloudfront\.net\/image-handler\/picture\/2019\/12\/shutterstock_1077682154\.jpg\'\)\] {
  background-image: url("https://d2jx2rerrg6sh3.cloudfront.net/image-handler/picture/2019/12/shutterstock_1077682154.jpg");
}

.bg-\[url\(\'https\:\/\/healthincode\.com\/wp-content\/uploads\/2024\/08\/human-kidney-cross-section-scientific-background-3d-illustration-1024x574\.jpg\'\)\] {
  background-image: url("https://healthincode.com/wp-content/uploads/2024/08/human-kidney-cross-section-scientific-background-3d-illustration-1024x574.jpg");
}

.bg-\[url\(\'https\:\/\/img\.freepik\.com\/free-vector\/coronavirus-bacteria-design_23-2148500844\.jpg\'\)\] {
  background-image: url("https://img.freepik.com/free-vector/coronavirus-bacteria-design_23-2148500844.jpg");
}

.bg-\[url\(\'https\:\/\/img\.freepik\.com\/premium-photo\/vibrant-glowing-representation-liver-highlighting-its-importance-human-anatomy-biology-against-dark-background_983055-26072\.jpg\'\)\] {
  background-image: url("https://img.freepik.com/premium-photo/vibrant-glowing-representation-liver-highlighting-its-importance-human-anatomy-biology-against-dark-background_983055-26072.jpg");
}

.bg-\[url\(\'https\:\/\/img\.pikbest\.com\/ai\/illus_our\/20230529\/db5c25acd5ae52b71d3a9ee320b2cc92\.jpg\!w700wp\'\)\] {
  background-image: url("https://img.pikbest.com/ai/illus_our/20230529/db5c25acd5ae52b71d3a9ee320b2cc92.jpg!w700wp");
}

.bg-\[url\(\'https\:\/\/media\.istockphoto\.com\/id\/1272365959\/photo\/hepatitis-virus-with-human-liver\.jpg\?s\=612x612\&w\=0\&k\=20\&c\=0Y8eNGsdsl3uqCmT55eqYD4keGSByw_zVKtm8VJowqg\=\'\)\] {
  background-image: url("https://media.istockphoto.com/id/1272365959/photo/hepatitis-virus-with-human-liver.jpg?s=612x612&w=0&k=20&c=0Y8eNGsdsl3uqCmT55eqYD4keGSByw_zVKtm8VJowqg=");
}

.bg-\[url\(\'https\:\/\/media\.istockphoto\.com\/id\/1311000189\/photo\/sars-cov-2\.jpg\?s\=612x612\&w\=0\&k\=20\&c\=2hAOP0OusSah9O8BftPH9d2-qHxkFKlSSKvKoBvqqIE\=\'\)\] {
  background-image: url("https://media.istockphoto.com/id/1311000189/photo/sars-cov-2.jpg?s=612x612&w=0&k=20&c=2hAOP0OusSah9O8BftPH9d2-qHxkFKlSSKvKoBvqqIE=");
}

.bg-\[url\(\'https\:\/\/media\.istockphoto\.com\/id\/1386446380\/photo\/human-kidney-cross-section-3d-illustration\.jpg\?s\=612x612\&w\=0\&k\=20\&c\=V-fOyNI_E4Rlw-1A_jrgotgz8TpXP46xXsLapho0wUY\=\'\)\] {
  background-image: url("https://media.istockphoto.com/id/1386446380/photo/human-kidney-cross-section-3d-illustration.jpg?s=612x612&w=0&k=20&c=V-fOyNI_E4Rlw-1A_jrgotgz8TpXP46xXsLapho0wUY=");
}

.bg-\[url\(\'https\:\/\/png\.pngtree\.com\/background\/20240412\/original\/pngtree-microscopic-view-of-cells-exploring-life-and-biology-through-medicine-and-picture-image_8461110\.jpg\'\)\] {
  background-image: url("https://png.pngtree.com/background/20240412/original/pngtree-microscopic-view-of-cells-exploring-life-and-biology-through-medicine-and-picture-image_8461110.jpg");
}

.bg-\[url\(\'https\:\/\/png\.pngtree\.com\/thumb_back\/fh260\/back_our\/20190623\/ourmid\/pngtree-c4d-medical-medical-research-brain-cell-biology-science-experiment-poster-image_252451\.jpg\'\)\] {
  background-image: url("https://png.pngtree.com/thumb_back/fh260/back_our/20190623/ourmid/pngtree-c4d-medical-medical-research-brain-cell-biology-science-experiment-poster-image_252451.jpg");
}

.bg-\[url\(\'https\:\/\/png\.pngtree\.com\/thumb_back\/fh260\/background\/20230720\/pngtree-spiraling-dna-structure-an-abstract-representation-of-molecular-biology-image_3715122\.jpg\'\)\] {
  background-image: url("https://png.pngtree.com/thumb_back/fh260/background/20230720/pngtree-spiraling-dna-structure-an-abstract-representation-of-molecular-biology-image_3715122.jpg");
}

.bg-\[url\(\'https\:\/\/publish\.purewow\.net\/wp-content\/uploads\/sites\/2\/2024\/07\/Types-of-Herbs-UNI\.jpg\'\)\] {
  background-image: url("https://publish.purewow.net/wp-content/uploads/sites/2/2024/07/Types-of-Herbs-UNI.jpg");
}

.bg-\[url\(\'https\:\/\/sph\.umich\.edu\/pursuit\/2021posts\/images\/SarsCov2\.jpg\'\)\] {
  background-image: url("https://sph.umich.edu/pursuit/2021posts/images/SarsCov2.jpg");
}

.bg-\[url\(\'https\:\/\/sriaas\.com\/wp-content\/themes\/srayurvedaone\/build\/img\/Kidney-bg-2\.webp\'\)\] {
  background-image: url("https://sriaas.com/wp-content/themes/srayurvedaone/build/img/Kidney-bg-2.webp");
}

.bg-\[url\(\'https\:\/\/sriaas\.com\/wp-content\/uploads\/2023\/12\/kidney_disorders_bg\.jpg\'\)\] {
  background-image: url("https://sriaas.com/wp-content/uploads/2023/12/kidney_disorders_bg.jpg");
}

.bg-\[url\(\'https\:\/\/t3\.ftcdn\.net\/jpg\/02\/02\/34\/48\/240_F_202344852_JqcipZQcJLDEZHS6dVA6PQX7VhdSlMku\.jpg\'\)\] {
  background-image: url("https://t3.ftcdn.net/jpg/02/02/34/48/240_F_202344852_JqcipZQcJLDEZHS6dVA6PQX7VhdSlMku.jpg");
}

.bg-\[url\(\'https\:\/\/t3\.ftcdn\.net\/jpg\/08\/89\/84\/56\/360_F_889845692_iYA0AUAxy9ker0VrE9oiQbBMHHnUnll8\.jpg\'\)\] {
  background-image: url("https://t3.ftcdn.net/jpg/08/89/84/56/360_F_889845692_iYA0AUAxy9ker0VrE9oiQbBMHHnUnll8.jpg");
}

.bg-\[url\(\'https\:\/\/t3\.ftcdn\.net\/jpg\/08\/90\/21\/02\/360_F_890210294_6fz9BMw2lA0X154aDv1SrUalZOk4vaQr\.jpg\'\)\] {
  background-image: url("https://t3.ftcdn.net/jpg/08/90/21/02/360_F_890210294_6fz9BMw2lA0X154aDv1SrUalZOk4vaQr.jpg");
}

.bg-\[url\(\'https\:\/\/thumbs\.dreamstime\.com\/b\/neurons-nervous-system-medicine-biology-background-creative-ai-neurons-nervous-system-medicine-biology-background-design-325091377\.jpg\'\)\] {
  background-image: url("https://thumbs.dreamstime.com/b/neurons-nervous-system-medicine-biology-background-creative-ai-neurons-nervous-system-medicine-biology-background-design-325091377.jpg");
}

.bg-\[url\(\'https\:\/\/thumbs\.dreamstime\.com\/b\/view-kidney-organs-blurred-background-ai-generated-content-design-instagram-facebook-wall-painting-wallpaper-photo-325042652\.jpg\'\)\] {
  background-image: url("https://thumbs.dreamstime.com/b/view-kidney-organs-blurred-background-ai-generated-content-design-instagram-facebook-wall-painting-wallpaper-photo-325042652.jpg");
}

.bg-\[url\(\'https\:\/\/w0\.peakpx\.com\/wallpaper\/583\/599\/HD-wallpaper-cell-biology-background-cellular\.jpg\'\)\] {
  background-image: url("https://w0.peakpx.com/wallpaper/583/599/HD-wallpaper-cell-biology-background-cellular.jpg");
}

.bg-\[url\(\'https\:\/\/www\.yourgenome\.org\/wp-content\/uploads\/2023\/11\/1600-shutterstock_2112088307\.jpg\'\)\] {
  background-image: url("https://www.yourgenome.org/wp-content/uploads/2023/11/1600-shutterstock_2112088307.jpg");
}

.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.from-\[\#774EA5\] {
  --tw-gradient-from: #774ea5 var(--tw-gradient-from-position);
  --tw-gradient-to: #774ea500 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-\[transparent\] {
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.bg-cover {
  background-size: cover;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-center {
  background-position: center;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-10 {
  padding: 2.5rem;
}

.p-12 {
  padding: 3rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[10px\] {
  padding: 10px;
}

.p-\[2px\] {
  padding: 2px;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-\[8px\] {
  padding-bottom: 8px;
}

.pl-0 {
  padding-left: 0;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-\[10px\] {
  padding-left: 10px;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-\[10px\] {
  padding-right: 10px;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-\[8px\] {
  padding-top: 8px;
}

.text-center {
  text-align: center;
}

.font-cursive {
  font-family: cursive;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-verdana {
  font-family: verdana;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-\[15px\] {
  font-size: 15px;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-\[17px\] {
  font-size: 17px;
}

.text-\[18px\] {
  font-size: 18px;
}

.text-\[20px\] {
  font-size: 20px;
}

.text-\[22px\] {
  font-size: 22px;
}

.text-\[25px\] {
  font-size: 25px;
}

.text-\[28px\] {
  font-size: 28px;
}

.text-\[30px\] {
  font-size: 30px;
}

.text-\[40px\] {
  font-size: 40px;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.italic {
  font-style: italic;
}

.tracking-\[2px\] {
  letter-spacing: 2px;
}

.tracking-\[3px\] {
  letter-spacing: 3px;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-\[\#006D77\], .text-\[\#006d77\] {
  --tw-text-opacity: 1;
  color: rgb(0 109 119 / var(--tw-text-opacity));
}

.text-\[\#176264\] {
  --tw-text-opacity: 1;
  color: rgb(23 98 100 / var(--tw-text-opacity));
}

.text-\[\#238072\] {
  --tw-text-opacity: 1;
  color: rgb(35 128 114 / var(--tw-text-opacity));
}

.text-\[\#256969\] {
  --tw-text-opacity: 1;
  color: rgb(37 105 105 / var(--tw-text-opacity));
}

.text-\[\#ebe8e8\] {
  --tw-text-opacity: 1;
  color: rgb(235 232 232 / var(--tw-text-opacity));
}

.text-\[\#efcece\] {
  --tw-text-opacity: 1;
  color: rgb(239 206 206 / var(--tw-text-opacity));
}

.text-\[\#ffa16f\] {
  --tw-text-opacity: 1;
  color: rgb(255 161 111 / var(--tw-text-opacity));
}

.text-\[\#ffc3c3\] {
  --tw-text-opacity: 1;
  color: rgb(255 195 195 / var(--tw-text-opacity));
}

.text-\[orange\] {
  --tw-text-opacity: 1;
  color: rgb(255 165 0 / var(--tw-text-opacity));
}

.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity));
}

.text-orange-400 {
  --tw-text-opacity: 1;
  color: rgb(251 146 60 / var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity));
}

.text-orange-600 {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[0_3px_10px_0_rgba\(0\,0\,0\,0\.5\)\] {
  --tw-shadow: 0 3px 10px 0 #00000080;
  --tw-shadow-colored: 0 3px 10px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-\[3px_0px_10px_0px_rgba\(0\,0\,0\,0\.5\)\] {
  --tw-shadow: 3px 0px 10px 0px #00000080;
  --tw-shadow-colored: 3px 0px 10px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[2s\] {
  transition-property: 2s;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[6s\] {
  transition-property: 6s;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-200 {
  transition-duration: .2s;
}

.duration-custom {
  transition-duration: 1s;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.animate-duration-500 {
  --tw-animate-duration: .5s;
  animation-duration: var(--tw-animate-duration);
}

.hover\:-mt-\[20px\]:hover {
  margin-top: -20px;
}

.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:bg-\[\#174f54\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(23 79 84 / var(--tw-bg-opacity));
}

.hover\:bg-\[\#fbb85b\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(251 184 91 / var(--tw-bg-opacity));
}

.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity));
}

.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

.hover\:text-\[\#ffa16f\]:hover {
  --tw-text-opacity: 1;
  color: rgb(255 161 111 / var(--tw-text-opacity));
}

.hover\:text-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

.hover\:text-orange-500:hover {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity));
}

.hover\:text-orange-600:hover {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:shadow-2xl:hover {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.focus\:border-transparent:focus {
  border-color: #0000;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity));
}

.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}

@media (width >= 640px) {
  .sm\:ml-\[0px\] {
    margin-left: 0;
  }
}

@media (width >= 768px) {
  .md\:block {
    display: block;
  }

  .md\:hidden {
    display: none;
  }

  .md\:p-10 {
    padding: 2.5rem;
  }
}

@media (width >= 1024px) {
  .lg\:absolute {
    position: absolute;
  }

  .lg\:left-\[40vw\] {
    left: 40vw;
  }

  .lg\:top-1 {
    top: .25rem;
  }

  .lg\:m-0 {
    margin: 0;
  }

  .lg\:-ml-10 {
    margin-left: -2.5rem;
  }

  .lg\:-ml-5 {
    margin-left: -1.25rem;
  }

  .lg\:-mt-32 {
    margin-top: -8rem;
  }

  .lg\:-mt-40 {
    margin-top: -10rem;
  }

  .lg\:-mt-\[0px\] {
    margin-top: 0;
  }

  .lg\:-mt-\[100px\] {
    margin-top: -100px;
  }

  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:ml-\[200px\] {
    margin-left: 200px;
  }

  .lg\:ml-\[220px\] {
    margin-left: 220px;
  }

  .lg\:ml-\[30px\] {
    margin-left: 30px;
  }

  .lg\:ml-\[500px\] {
    margin-left: 500px;
  }

  .lg\:ml-\[50px\] {
    margin-left: 50px;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:mt-20 {
    margin-top: 5rem;
  }

  .lg\:mt-4 {
    margin-top: 1rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:h-\[100px\] {
    height: 100px;
  }

  .lg\:h-\[150px\] {
    height: 150px;
  }

  .lg\:h-\[200px\] {
    height: 200px;
  }

  .lg\:h-\[230px\] {
    height: 230px;
  }

  .lg\:h-\[250px\] {
    height: 250px;
  }

  .lg\:h-\[30vw\] {
    height: 30vw;
  }

  .lg\:h-\[350px\] {
    height: 350px;
  }

  .lg\:h-\[400px\] {
    height: 400px;
  }

  .lg\:h-\[430px\] {
    height: 430px;
  }

  .lg\:h-\[450px\] {
    height: 450px;
  }

  .lg\:h-\[550px\] {
    height: 550px;
  }

  .lg\:h-\[750px\] {
    height: 750px;
  }

  .lg\:h-\[800px\] {
    height: 800px;
  }

  .lg\:h-\[80px\] {
    height: 80px;
  }

  .lg\:w-\[1000px\] {
    width: 1000px;
  }

  .lg\:w-\[100px\] {
    width: 100px;
  }

  .lg\:w-\[100vw\] {
    width: 100vw;
  }

  .lg\:w-\[200px\] {
    width: 200px;
  }

  .lg\:w-\[220px\] {
    width: 220px;
  }

  .lg\:w-\[300px\] {
    width: 300px;
  }

  .lg\:w-\[330px\] {
    width: 330px;
  }

  .lg\:w-\[350px\] {
    width: 350px;
  }

  .lg\:w-\[400px\] {
    width: 400px;
  }

  .lg\:w-\[40vw\] {
    width: 40vw;
  }

  .lg\:w-\[430px\] {
    width: 430px;
  }

  .lg\:w-\[450px\] {
    width: 450px;
  }

  .lg\:w-\[45vw\] {
    width: 45vw;
  }

  .lg\:w-\[500px\] {
    width: 500px;
  }

  .lg\:w-\[50vw\] {
    width: 50vw;
  }

  .lg\:w-\[550px\] {
    width: 550px;
  }

  .lg\:w-\[55vw\] {
    width: 55vw;
  }

  .lg\:w-\[600px\] {
    width: 600px;
  }

  .lg\:w-\[610px\] {
    width: 610px;
  }

  .lg\:w-\[700px\] {
    width: 700px;
  }

  .lg\:w-\[75px\] {
    width: 75px;
  }

  .lg\:w-\[800px\] {
    width: 800px;
  }

  .lg\:w-\[80px\] {
    width: 80px;
  }

  .lg\:w-\[auto\] {
    width: auto;
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }

  .lg\:items-start {
    align-items: flex-start;
  }

  .lg\:items-center {
    align-items: center;
  }

  .lg\:justify-start {
    justify-content: flex-start;
  }

  .lg\:justify-center {
    justify-content: center;
  }

  .lg\:justify-between {
    justify-content: space-between;
  }

  .lg\:justify-around {
    justify-content: space-around;
  }

  .lg\:overflow-x-visible {
    overflow-x: visible;
  }

  .lg\:rounded-md {
    border-radius: .375rem;
  }

  .lg\:rounded-xl {
    border-radius: .75rem;
  }

  .lg\:rounded-tl-xl {
    border-top-left-radius: .75rem;
  }

  .lg\:rounded-tr-xl {
    border-top-right-radius: .75rem;
  }

  .lg\:p-0 {
    padding: 0;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:p-16 {
    padding: 4rem;
  }

  .lg\:p-20 {
    padding: 5rem;
  }

  .lg\:p-3 {
    padding: .75rem;
  }

  .lg\:p-4 {
    padding: 1rem;
  }

  .lg\:p-5 {
    padding: 1.25rem;
  }

  .lg\:pb-0 {
    padding-bottom: 0;
  }

  .lg\:pl-0 {
    padding-left: 0;
  }

  .lg\:pr-0 {
    padding-right: 0;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:text-\[16px\] {
    font-size: 16px;
  }

  .lg\:text-\[18px\] {
    font-size: 18px;
  }

  .lg\:text-\[20px\] {
    font-size: 20px;
  }

  .lg\:text-\[25px\] {
    font-size: 25px;
  }

  .lg\:text-\[30px\] {
    font-size: 30px;
  }

  .lg\:text-\[40px\] {
    font-size: 40px;
  }

  .lg\:text-\[45px\] {
    font-size: 45px;
  }

  .lg\:text-\[50px\] {
    font-size: 50px;
  }

  .lg\:text-\[60px\] {
    font-size: 60px;
  }

  .lg\:text-\[65px\] {
    font-size: 65px;
  }

  .lg\:font-bold {
    font-weight: 700;
  }

  .lg\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}
/*# sourceMappingURL=index.b21a2b76.css.map */
